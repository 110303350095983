@import 'scss/main.scss';

.ConceptsPage {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  .ConceptsPageInner {
    height: 100%;
  }

  .ConceptsPageOverlay {
    height: 100%;
  }
}

.ConceptLinksContainer {
  $concept-link-text-width: 45px;
  $concept-link-text-height: 49px;
  $concept-link-size: 125px;
  $concept-link-overlay-size: 3000px;

  max-width: 7 * $concept-link-size + 1px;
  min-width: 2 * $concept-link-size + 1px;
  width: 100%;

  .ConceptLink {
    width: $concept-link-size;
    height: $concept-link-size;
    cursor: pointer;

    .ConceptLinkInner {
      height: $concept-link-size;
    }
    
    span {
      font-size: $lg-font-size;
      font-weight: 600;
      color: $base-black;
      width: $concept-link-text-width;
      height: $concept-link-text-height;
    }
  
    &:hover span {
      color: $hover-base-black;
      transition: $compactTransition;
    }
  
    &.disabled span, &:hover.disabled span {
      color: $disabled-base-black;
    }

    &.disabled, &:hover.disabled {
      cursor: default;
    }

    .ConceptLinkOverlayContainer {
      position: relative;
    }

    .ConceptLinkOverlay {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      transition: $slowEaseInTransition;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  
    &.active .ConceptLinkOverlay {
      height: $concept-link-overlay-size;
      width: $concept-link-overlay-size;
      top: calc(-#{$concept-link-overlay-size} / 2);
      left: calc(-#{$concept-link-overlay-size} / 2);
      opacity: 1;
      background-color: $base-black;
    }
  }

}

