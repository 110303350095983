@import 'scss/main.scss';

.icon-x {
  height: 60px;
  width: 60px;
  cursor: pointer;

  span {
    color: $base-white;
    font-size: 30px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    position: relative;
    top: 16px;
    left: 21px;
  }
}
