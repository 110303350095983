@import 'scss/main.scss';

.ErrorPage {
  width: 100vw;
  height: 100vh;

  .ErrorPageInner {
    height: 100%;
  }

  .ErrorPageHeader {
    text-align: center;
  }
  
  .ErrorPageBody {
    text-align: center;
    font-size: 8px;
    width: 100px;
  }
}
