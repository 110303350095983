@import 'scss/main.scss';

.ConceptOverlay {
  position: absolute;
  display: none;
  width: 100vw;

  &.active {
    display: block;
    top: 0;
    left: 0;
    
    h1 {
      color: $base-white;
      text-transform: uppercase;
    }

    p {
      color: $base-white;
      text-transform: uppercase;
      font-weight: 600;
    }

    hr {
      width: 100%;
    }

    .ConceptProperty {
      .ConceptPropertyKey {
        color: $deep-space-sparkle;
      }

      .ConceptPropertyValue {
        margin-left: 20px;
        color: $base-white;
      }
    }
  }
}
