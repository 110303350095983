/*-- Colors -- */
$base-black: black;
$base-white: white;
$deep-space-sparkle: #495e6d;
$gainsboro: #DBDFE2;
/*-- Typeography --*/
    /*-- Fonts --*/
    $font-family: 'Helvetica Neue', sans-serif;
    /*-- Sizing --*/
    $lg-font-size: 40px;
    $sm-font-size: 16px;
    /*-- Alternate Colors --*/
    $hover-base-black: $deep-space-sparkle;
    $disabled-base-black: $gainsboro;
/*-- Animation --*/
$easeInTransition: ease-in 0.3s;
$compactTransition: all ease 0.3s;
$slowEaseInTransition: ease-in 0.5s;