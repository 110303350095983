@import 'scss/main.scss';

.concept-logo {
    width: auto;

    span {
        color: $base-black;
        font-size: $lg-font-size;
        font-family: $font-family;
        font-weight: 600;
        margin: 0;
        display: inline-block;
    }
    
    .concept-text {
        overflow: hidden;
        width: 30px;
        transition: $easeInTransition;
    }

    &:hover .concept-text {
        width: 197px;
    }
}