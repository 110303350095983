@import 'scss/main.scss';

.LandingPage {
    width: 100vw;
    height: 100vh;

    .LandingPageInner {
        width: 100%;
        height: 100%;
    }
}